.submenu-container{
    width: 100%;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    padding: 10%;
}
.submenu-container > p{
    display: flex;
    cursor: pointer;
    transition: color 10sec ease;
    /* padding: 5px; */
}
.submenu-container p:hover{
    color:rgb(56, 56, 217)
}
.img-sidebar {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .main-side{
    margin-left: 265px;
}
  .sidebar {
    /* position: relative; */
    color: #fff;
    height: 100vh;
    position: fixed;
    min-height: 100vh;
    width: 265px;
    background: #272829;
    /* transition: all 0.5s; */
  }
  
  .top_section {
    display: flex;
    align-items: center;
    padding: 20px 15px;
  }
  
  .logo-sidebar {
    font-size: 30px;
    height: 50px;
    width: 80%;
  }
  
  .bars {
    display: none;
    font-size: 15px;
    /* margin-left: 10px; */
  }
  
  .link {
    padding-left: 30px;
    display: flex;
    color: #a7a7a7;
    padding: 5px 10px;
    gap: 15px;
    transition: all 0.5s;
    margin-bottom: 10px;
    font-size: 15px;
    margin-left: 10px;
    /* font-family: "Clash Display Light" !important; */
  }
  
  .link:hover {
    width: 90%;
    background: rgba(255, 255, 255, 0.18);
    color: white !important;
    border-radius: 5px;
  }
  
  .link .arrow-icon svg {
    display: none;
  }
  
  .active {
    width: 90%;
    background: rgba(255, 255, 255, 0.18);
    color: white !important;
    border-radius: 5px;
    color:#3578e5 !important;
  }
  
  .active .arrow-icon svg {
    display: block;
  }
  
  .arrow-icon {
    float: right;
  }
  .active .link_text{}
  .icon,
  .link_text {
    font-size: 15px;
  }

  .dropdown-section{
    display: none !important;
}

  @media (max-width: 1000px){
        .bars{
            display: block;
        }
        .main-side{
            margin-left: 55px;
        }
        .dropdown-section{
            display: block !important;
        }
    }

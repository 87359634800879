.div-points{
    display: flex;
    flex-direction: column;
    min-height:60px;
    padding: 10px;
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.458);
    margin: 2%;
    /* border: 1px solid #3578e5; */
}
.div-points:hover{
    box-shadow: 0px 0px 10px 0px #3b82f680;
    border: 1px solid #3578e5;
}

    .meta-video:hover{
        transform: scale(1.3);
        box-shadow: 0px 0px 10px 0px #5350dd;
        border-radius: 5px;
        transition: 0.5s;
      }
    .meta-video{
      overflow: hidden;
    }
    .gen-image{
      border-radius: 10px;
      cursor: pointer;
      transition: all 0.2s ease-in; 
    }
    .gen-image:hover{
      transform: scale(1.2);
      -webkit-box-shadow: 5px 5px 5px -1px rgba(80, 94, 160, 0.77);
      -moz-box-shadow: 5px 5px 5px -1px rgba(80, 94, 160, 0.77);
      box-shadow: 5px 5px 5px -1px rgba(80, 94, 160, 0.77);
    }
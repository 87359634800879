/* variables */
:root{
  --link-text-font-size:.6rem;
  --link-text-line-height: 1rem; 
  --body-text-font-size:.75rem;
  --body-text-line-height: 1.4rem;
  --header-text-font-size:1.8rem;
  --header-text-line-height:3rem;
  --sub-heading-text-font-size:1.12em;
  --sub-heading-text-line-height:1.8rem;
}
body{
  font-family: Arial, Helvetica, sans-serif;
  background-color: #1B1A1C;
  color: #bfbfbf !important;
}
img{
  margin: 1em;
}
.font-body{
  font-size: var(--body-text-font-size);
  line-height: var(--body-text-line-height);
  font-weight: regular !important;
  text-align: justify;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.text-header{
  font-size: var(--header-text-font-size);
  line-height: var(--header-text-line-height);
  font-weight: bolder;
  /* padding-top: 1em; */
}
.text-sub-heading{
  font-size: var(--sub-heading-text-font-size);
  line-height: var(--sub-heading-text-line-height);
  font-weight: bold;
  padding-bottom: .2em !important;
  margin: .1em !important;
}
.text-link{
  font-size: var(--link-text-font-size);
  line-height: var(--link-text-line-height);
}
a{
  text-decoration: none;
  color: #3578e5;
}
/* Extra small devices (phones) */
@media (min-width: 576px) {
  /* Styles for screens larger than or equal to 576px wide */
  .font-body{
    font-size: var(--body-text-font-size);
    line-height: var(--body-text-line-height);
  }
  .text-header{
    font-size: var(--header-text-font-size);
    line-height: var(--header-text-line-height);
  }
  .text-sub-heading{
    font-size: var(--sub-heading-text-font-size);
    line-height: var(--sub-heading-text-line-height);
  }
  .link-text{
    font-size: var(--link-text-font-size);
    line-height: var(--link-text-line-height);
  }

  
}

@media (max-width: 576px) {
  .main-side{
    padding: 1em !important;
  }
  
}
/* Small devices (tablets) */
@media (min-width: 768px) {
  /* Styles for screens larger than or equal to 768px wide */
  .font-body{
    font-size: calc(var(--body-text-font-size)*1.1);
    line-height: calc(var(--body-text-line-height)*1.1);
  }
  .text-header{
    font-size: calc(var(--header-text-font-size)*1.1);
    line-height: calc(var(--header-text-line-height)*1.1);
  }
  .text-sub-heading{
    font-size: calc(var(--sub-heading-text-font-size)*1.1);
    line-height: calc(var(--sub-heading-text-line-height)*1.1);
  }
  .link-text{
    font-size: calc(var(--link-text-font-size)*1.1);
    line-height: calc(var(--link-text-line-height)*1.1);
  }
}

/* Medium devices (desktops) */
@media (min-width: 992px) {
  /* Styles for screens larger than or equal to 992px wide */
  .font-body{
    font-size: calc(var(--body-text-font-size)*1.2);
    line-height: calc(var(--body-text-line-height)*1.2);
  }
  .text-header{
    font-size: calc(var(--header-text-font-size)*1.2);
    line-height: calc(var(--header-text-line-height)*1.2);
  }
  .text-sub-heading{
    font-size: calc(var(--sub-heading-text-font-size)*1.2);
    line-height: calc(var(--sub-heading-text-line-height)*1.2);
  }
  .link-text{
    font-size: calc(var(--link-text-font-size)*1.2);
    line-height: calc(var(--link-text-line-height)*1.2);
  }
  .link-text{
    font-size: calc(var(--link-text-font-size)*1.2);
    line-height: calc(var(--link-text-line-height)*1.2);
  }
}

/* Large devices (large desktops) */
@media (min-width: 1200px) {
  /* Styles for screens larger than or equal to 1200px wide */
  .font-body{
    font-size: calc(var(--body-text-font-size)*1.3);
    line-height: calc(var(--body-text-line-height)*1.3);
  }
  .text-header{
    font-size: calc(var(--header-text-font-size)*1.3);
    line-height: calc(var(--header-text-line-height)*1.3);
  }
  .text-sub-heading{
    font-size: calc(var(--sub-heading-text-font-size)*1.3);
    line-height: calc(var(--sub-heading-text-line-height)*1.3);
  }
  .link-text{
    font-size: calc(var(--link-text-font-size)*1.3);
    line-height: calc(var(--link-text-line-height)*1.3);
  }
}

@media (min-width: 1400px) {
  /* Styles for screens larger than or equal to 1400px wide */
  .font-body{
    font-size: calc(var(--body-text-font-size)*1.4);
    line-height: calc(var(--body-text-line-height)*1.4);
  }
  .text-header{
    font-size: calc(var(--header-text-font-size)*1.4);
    line-height: calc(var(--header-text-line-height)*1.4);
  }
  .text-sub-heading{
    font-size: calc(var(--sub-heading-text-font-size)*1.4);
    line-height: calc(var(--sub-heading-text-line-height)*1.4);
  }
  .link-text{
    font-size: calc(var(--link-text-font-size)*1.4);
    line-height: calc(var(--link-text-line-height)*1.4);
  }

}
@media (min-width: 1600px) {
  /* Styles for screens larger than or equal to 1600px wide */
  .font-body{
    font-size: calc(var(--body-text-font-size)*1.5);
    line-height: calc(var(--body-text-line-height)*1.5);
  }
  .text-header{
    font-size: calc(var(--header-text-font-size)*1.5);
    line-height: calc(var(--header-text-line-height)*1.5);
  }
  .text-sub-heading{
    font-size: calc(var(--sub-heading-text-font-size)*1.5);
    line-height: calc(var(--sub-heading-text-line-height)*1.5);
  }
  .link-text{
    font-size: calc(var(--link-text-font-size)*1.5);
    line-height: calc(var(--link-text-line-height)*1.5);
  }
}
@media (min-width: 1800px) {
  /* Styles for screens larger than or equal to 1800px wide */
  .font-body{
    font-size: calc(var(--body-text-font-size)*1.6);
    line-height: calc(var(--body-text-line-height)*1.6);
  }
  .text-header{
    font-size: calc(var(--header-text-font-size)*1.6);
    line-height: calc(var(--header-text-line-height)*1.6);
  }
  .text-sub-heading{
    font-size: calc(var(--sub-heading-text-font-size)*1.6);
    line-height: calc(var(--sub-heading-text-line-height)*1.6);
  }
  .link-text{
    font-size: calc(var(--link-text-font-size)*1.6);
    line-height: calc(var(--link-text-line-height)*1.6);
  }
}
@media (min-width: 2000px) {
  /* Styles for screens larger than or equal to 2000px wide */
  .font-body{
    font-size: calc(var(--body-text-font-size)*1.7);
    line-height: calc(var(--body-text-line-height)*1.7);
  }
  .text-header{
    font-size: calc(var(--header-text-font-size)*1.7);
    line-height: calc(var(--header-text-line-height)*1.7);
  }
  .text-sub-heading{
    font-size: calc(var(--sub-heading-text-font-size)*1.7);
    line-height: calc(var(--sub-heading-text-line-height)*1.7);
  }
  .link-text{
    font-size: calc(var(--link-text-font-size)*1.7);
    line-height: calc(var(--link-text-line-height)*1.7);
  }
}
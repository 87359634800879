.submenu-container{
    width: 100%;
    border-left: 1px solid #444950;
    padding: 10%;
}
.submenu-container > a{
    display: flex;
    cursor: pointer;
    transition: all 10sec ease;
    padding: 5px;
    color: white;
}
.submenu-container a:hover{
    transition: all 10sec ease;
    color:#3578e5;
}
.dropdown-item{
    color: #bfbfbf;
}
.dropdown-divider{
    border: .5px solid #444950 !important; 
}
.breadcrumb-overview{
    margin-top: 1em;
    margin-bottom: 1.5em;
}
.breadcrumb-overview > div{
    color: #bfbfbf;
    padding: 3px 15px;
    background-color: #44495096;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 15px;
}
.breadcrumb-overview :first-child{
    padding: auto;
}

.active-color{
    color: #3578e5;
}

.prev-navigation{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    min-height: 70px;
    height: auto;
    padding: 15px;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.458);
    border-radius: 5px;
    text-align: left;
}
.prev-navigation:hover{
    box-shadow: 0px 0px 10px 0px #3b82f680;
    border: 1px solid #3578e5;
}
.next-navigation:hover{
    box-shadow: 0px 0px 10px 0px #3b82f680;
    border: 1px solid #3578e5;
}
.next-navigation{
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 70px;
    margin-top: 20px;
    margin-bottom: 20px;
    height: auto;
    padding: 15px;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.458);
    border-radius: 5px;
    justify-content: flex-end !important;
    text-align: right;

}

.support-container{
    padding: 10px;
    border-top: 1px solid #444950;
    text-align: justify !important;

}
.home-icon{
    cursor: pointer;
}
.home-icon:hover svg{
    color: #3578e5 !important;
}